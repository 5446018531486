import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getSaleAddons: createCancelTokenHandler('getSaleAddons'),
  getSaleAddonsTrash: createCancelTokenHandler('getSaleAddonsTrash'),
  getSaleAddonsActivitiesLog: createCancelTokenHandler('getSaleAddonsActivitiesLog')
}

export function getSaleAddons (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/club/sale-addons${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSaleAddons'].handleRequestCancellation().token
  })
}

export function getSaleAddon (id) {
  return axios({
    url: `v1/admin/club/sale-addons/${id}`,
    method: 'get'
  })
}

export function insertSaleAddon (payment) {
  return axios({
    url: 'v1/admin/club/sale-addons',
    data: payment,
    method: 'post'
  })
}

export function editSaleAddon (id, payment) {
  return axios.patch(`v1/admin/club/sale-addons/${id}`, payment)
}


export function deleteSaleAddon (id, force = false) {
  return axios({
    url: `v1/admin/club/sale-addons/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreSaleAddon (id) {
  return axios({
    url: `v1/admin/club/sale-addons/${id}/restore`,
    method: 'put'
  })
}


export function getSaleAddonsTrash (page, filters = {}, sorts = []) {

  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/club/sale-addons?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSaleAddonsTrash'].handleRequestCancellation().token
  })
}

export function getSaleAddonsActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/admin/club/sale-addons/${id}/activity-logs${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSaleAddonsActivitiesLog'].handleRequestCancellation().token
  })
}
